.about__wrap
{
    
}
.about__block:hover {
    transform: scale(1.5);
    background-color: rgba(255, 255, 255, 0.3); /* Lighter background on hover */
    border-color: #ccc; /* Lighter border on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transition */
}
.about__wrap h1 {
    margin-left: 10vw;
    margin-top: 35vh;
    font-size: 5vw;
    margin-bottom: 0;
    color: white;
}

.about__wrap p {
    margin-top: 2vh;
    margin-left: 10vw;
    font-size: 4vh;
    color: gray;
}

.about__blocks {
    display: flex;
    gap: 2.5vw;
    margin-top: 10vh;
    padding-bottom: 20vh;
    flex-wrap: wrap;
}

.about__block {
    width: 24vw;
    height: 55vh;
    background-color: rgba(128, 128, 128, 0.2);
    border: 2px solid white;
    border-radius: 0.5vw;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.about__block h4 {
    margin-top: 5vh;
    margin-left: 2vw;
    font-size: 2.3vw;
    color: white;
    margin-bottom: 0;
}

.about__block p {
    margin-left: 2.5vw;
    width: 80%;
    margin-top: 5vh;
}

.about__block:first-child {
    margin-left: 10vw;
}

.about__block:last-child {
    margin-right: 10vw;
}

.about__block:hover {
    transform: scale(1.1);
    background-color: rgba(255, 255, 255, 0.3); /* Lighter background on hover */
    border-color: #ccc; /* Lighter border on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

@media (max-width: 720px) {
    .about__block {
        width: 80vw;
        margin: 10vw;
        text-align: center;
        border-radius: 5vw;
        height: 55vh;
    }

    .about__wrap h1 {
        font-size: 5.775vh;
        padding-top: 0;
        margin-top: 10vh !important;
    }

    .about__block p {
        margin: 0 auto;
        font-size: 3.5vh;
    }

    .about__block h4 {
        font-size: 4.5vh;
        padding-bottom: 5vh;
    }

    .about__wrap p{
        width: 80vw !important;
    }
}