.gen__wrap
{
    background-image: url('back1.png');
    background-size: cover;
    background-repeat: no-repeat;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
.home__wrap
{
    padding-top: 20vh;
    display: flex;
    animation: fadeIn 2s forwards;
}

.home__text
{
    flex: 7;
}
.home__text h1
{
    margin-left: 10vw;
    margin-top: 6vh;
    font-size: 5vw;
    margin-bottom: 0;
    color: white;
}
.home__text p
{
    margin-top: 2vh;
    margin-left: 10vw;
    font-size: 4vh;
    color: gray;
}
.home__img
{
    flex: 3;
}
.img__home
{
    margin-top: 7.5vh;
    width: 50vw;
    height: auto;
    position: absolute;
    margin-left: -20vw;
}
.home__btns
{
    margin-top: 7.5vh;
    margin-left: 10vw;
    display: flex;
}

.home__btns button {
    width: 12vw;
    height: 3vw;
    border-radius: 0.7vw;
    border: 1px solid blue;
    margin-left: 2.5vw;
    background-color: rgba(128, 128, 128, 0.2);
    box-sizing: border-box;  /* Это заставит кнопку учитывать границу в её размере */
}

.home__btns button:first-child {
    margin-left: 0;
}

.home__btns button a {
    color: rgb(255, 255, 255);
    font-size: 1.8vh;
    font-weight: bold;
    display: block;  /* Сделает ссылку блочным элементом, чтобы она занимала всю площадь кнопки */
    height: 100%;  /* Заставит ссылку растягиваться по высоте кнопки *//* Чтобы текст был по центру */
    line-height: 2.5vw;  /* Выравнивание текста по вертикали */
}
@media (max-width:720px){
    .home__wrap
    {
        flex-direction: column-reverse;
        padding-bottom: 0;
        overflow-x: hidden;
    }
    .home__text h1{
        margin-top: 50vw;
        font-size: 4.775vh;
        margin-right: 10vw;
    }
    .img__home
    {
        width: 100vw;
        margin-left:0;
        margin-top: -20vw;
    }
    .home__btns
    {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;
    }
    .home__btns button
    {
        width: 80vw;
        height: 5vh;
        margin-left: 0;
        border-radius: 3vw;
    }
    .home__btns button a {
        margin-top: 1.9vh !important;
    }
    .home__btns button:first-child
    {
        background-color: blue;
        margin-bottom: 2.5vh;
        margin-top: 2.5vh;
    }
    .home__btns button:last-child{
        display: none;
    }
    .home__text p {
        font-size: 2.7vh;
        margin-top: 0;
    }
    .gen__wrap
    {
        overflow-x: hidden;
    }
}