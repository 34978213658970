.prikol__wrap{
    background-image: url('./img.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    overflow-x: hidden;
}
.ex__wrap{
    margin-top: -5vh;
}
.ex__wrap img
{
    width: 80vw;
    margin-top:7.5vh;
    margin-bottom: 20vh;
    padding-right: 10vw;
    padding-left: 10vw;
}
.ex__wrap h1 {
    padding-top: 40vh;
    color: white;

}
@media (max-width:720px){
    .ex__wrap h1 {
        padding-top: 20vh;
        width: 80vw;
        margin-left: 10vw;
    
    }
}