.port__wrap {
    height: 300vh;
}
.portfolio__wrap
{
    background-image: url('back1.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.port__wrap div {
    height: 90vh;
    width: auto;
    
}
.port__wrap div:last-child{
    height: 0vh !important;
}
.cont__wr:nth-child(2){
    margin-top: -50vh;
}
.cont__wr:nth-child(3)
{
    margin-top: -20vh;
}
.mySwiper img{
    transition: transform 0.1s ease-in-out;
}
.mySwiper img:hover{
    transform: scale(1.05);
}
.bus_pres::before {
    content: 'Business presentaion';
    color: white;
    position: absolute;
    top: 27.5vh;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 200%;
}

.bus_pres {
    background-size: contain;
    background-repeat: no-repeat;
    height: auto;
    width: 100%;
}

.pers_port {
    background-size: contain;
    background-repeat: no-repeat;
    height: auto;
    width: 100%;
}
.pers_port::before {
    content: 'Personal portfolio';
    color: white;
    position: absolute;
    top: 117vh;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 200%;
}

.cont__wr {
    padding-top: 10vh;
}

.e_store {
    background-size: contain;
    background-repeat: no-repeat;
    height: auto;
    width: 100%;
    ;
}
.e_store::before {
    content: 'Online store';
    color: white;
    position: absolute;
    top: 207vh;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 200%;
}
.mySwiper img {
    width: 60vw;
    margin-left: 20vw;
    margin-top: 14%;
    border-radius: 2vw;
}

.mySwiper {
    width: 100px;
    height: 80%;
}

.bus_pres swiper {
    width: 100px;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    content: 'next';
    top: 0%;
    margin-right: 30vw;
    position: absolute;
}

.swiper-slide {
    width: auto;
}

.swiper-slide.swiper-slide-active {
    width: auto;
}

.swiper-button-prev:after {
    position: absolute;
    top: 0%;
    margin-left: 30vw;
}
@media (max-width:720px){

.port__wrap div{
    height: 40vh;
}
.bus_pres::before {
    content: 'Business presentaion';
    color: white;
    position: absolute;
    top: 22vh;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size:120%;
}
.pers_port::before {
    content: 'Personal portfolio';
    color: white;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 120%;
    top: 62vh;
}
.e_store::before {
    content: 'Online store';
    color: white;
    position: absolute;
    top: 102vh;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 120%;
}
.port__wrap{
    height: 160vh;
}
.bus_pres{
    margin-top: 10vh;
}
.mySwiper img {
    width: 80vw;
    margin-left: 10vw;
    margin-top: 14%;
    border-radius: 2vw;
}
}