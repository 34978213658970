.contact__wrap {
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 20vh;
    height: auto;
    overflow-x: hidden;
}

.cont__wrap {
    background-image: url('image.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.contact__wrap h1 {
    font-size: 5vw;
    margin-bottom: 0;
    color: white;
}

.contact__wrap p {
    margin-top: 2vh;
    font-size: 4vh;
    color: gray;
}

.contact__inputs form {
    width: 60vw;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 4vh;
}

.contact__inputs form .input-group {
    width: 35%;
    display: flex;
    flex-direction: column;
    margin-bottom: 3vh;
}

.contact__inputs form .input-group label {
    font-size: 2vh;
    margin-bottom: 5px;
    width: auto;
}

.contact__inputs form textarea {
    width: 80%;
    height: 2.5vh;
    background-color: rgba(124, 124, 124, 0.205);
    border-radius: 0.7vw;
    border: 1px solid white;
    padding: 1vw;
    font-size: 2vh;
    color: white;
    line-height: 2vh;
    font-family: Arial, Helvetica, sans-serif;
}

.contact__inputs form textarea.error {
    border: solid red;
    background-color: rgba(255, 0, 0, 0.2);

}

.contact__inputs form textarea.info {
    margin-right: 0 !important;
    width: 180% !important;
    padding-bottom: 7vh !important;
    padding-left: 1vw;
    padding-top: 1vw;
    font-size: 3vh !important;
    color: rgb(255, 255, 255);
    white-space: pre-wrap;
}

.contact__inputs form textarea.info[type="text"] {
    padding-top: 2vw !important;
    padding-left: 0.75vw !important;
}

.contact__inputs form button {
    width: 11vw;
    margin-right: 30vw;
    height: 2.25vw;
    background-color: blue;
    border-radius: 0.5vw;
    color: white;
    border: 1px solid blue;
    font-family: Arial;
    font-weight: bold;
    font-size: 1.8vh;
    margin-bottom: 10vh;
}

.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.input-group label {
    margin-bottom: 5px;
    display: inline;
    color: gray;
    font-size: 3vh;
    font-weight: normal;
}

.contact__inputs form .input-group:nth-child(5) textarea {
    width: 180% !important;
    height: 15vh !important;
    padding: 1vw;
    font-size: 3vh;
    color: white;
    white-space: pre-wrap;
}
.scroll-to-bottom {
    overflow-y: hidden;
}

.scroll-to-bottom > div {
    height: 10px;
    transition: height 0.5s ease-in-out 0.2s;
}

/* Стили для уведомления */
.notification {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 2vh;
    z-index: 1000;
    text-align: center;
}

@media (max-width: 720px) {
    .contact__inputs form textarea {
        width: 77.5vw;
        display: block;
        margin-right: 0;
        height: 5vh;
        border-radius: 2vw;
        line-height: 3.25vh;
    }

    .contact__inputs form .input-group {
        width: 100%;
    }

    .contact__inputs form textarea.info {
        width: 77.5vw !important;
        height: 15vh !important;
    }

    .contact__inputs form textarea.info label {
        color: #000 !important;
    }

    .contact__inputs form button {
        width: 79vw;
        height: 10vw;
        margin-right: -20vw;
        border-radius: 2vw;
        margin-top: 5vh;
    }

    .info label {
        color: white;
    }

    .contact__inputs form .input-group label {
        font-size: 3vh;
    }

    .contact__wrap h1 {
        font-size: 5vh;
        margin-top: 2vh;
    }

    .contact__wrap p {
        font-size: 3vh;
    }

    .contact__wrap {
        padding-top: 10vh;
    }
}