.footer {
    background-color: transparent; /* Adjust color as needed */
    color: white;
    text-align: center;
    padding: 20px 0 !important;
    padding-bottom: 5vh !important;
}

.logo-container {
    margin-bottom: 0 !important;
    position: relative !important;
}

.logo {
    max-width: 20vw !important;
    margin-bottom: 1vh !important;
}

.logo-container::before {
    content: "";
    position: absolute;
    top: -20px; /* Adjust if needed to move the line up or down */
    left: 0;
    width: 100%;
    height: 1px; /* Adjust the thickness of the line */
    background-color: rgb(255, 255, 255);
}

.footer-nav {
    margin-bottom: 10px !important;
    display: flex !important;
    justify-content: center !important;
    flex-wrap: wrap !important;
}

.footer-nav a {
    color: white !important;
    margin: 5px 10px !important;
    text-decoration: none !important;
    transition: color 0.3s ease-in-out, transform 0.1s ease-in-out !important; /* Add transition for color */
}

.footer-nav a:hover {
    color: blue !important; /* Add the correct hover selector */
}

.footer p {
    margin: 10px 0 0 0 !important;
}

@media (max-width: 600px) {
    .footer-nav {
        flex-direction: column;
    }

    .footer-nav a {
        margin: 10px 0;
    }
    .logo{
        max-width: 75vw !important; 
    }
}
