.img__big1
{
    height: 47vh!important;
    margin-top: -8%!important;
    margin-right: 0 !important;
    margin-left: -5vw;
}
a{
    display: inline !important;
}
.img__big2
{
    height: 45vh !important;
    margin-right: 0 !important;
}
.servi__cont
{
 height: auto;
    background-image: url('./img_no_flashes.png');
    background-size: cover;
    background-repeat: no-repeat;   
}
.service__wrap
{
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 20vh;
    
}

.service__wrap h1 
{
    font-size: 5vw;
    margin-bottom: 0;
    color: white;
}
.service__wrap p
{
    margin-top: 2vh;
    font-size: 4vh;
    color: gray;
}.service__cont
{
    margin-top: 11vh;
}


.service__cont p
{
    font-size: 2.75vh;
    color: gray;
    font-weight: bold;
}
.service__cont h2{
    font-size: 2.3vw;
    color: white;
    margin-bottom: 0;
    margin-top: 3vw;
}
.service__cont h2,
.service__cont p
{
    margin-left: 4vw;
}
.serv__cont
{
    display: flex;
}
.serv__cont p
{
 margin-bottom: 0;
 height: 80%;
 font-weight: normal;
}
.serv__cont img
{


    height: 35vh;
    margin-top: -6%;
    margin-right: 4vw;
}
.service__left img
{
    margin-left: 5vw;
}
.service__right,
.service__left
{
    width: 80vw;
    height: 40vh;
    background-color: rgba(128, 128, 128,0.2);
    border: 2px solid white;
    border-radius: 0.5vw;
    margin-right: 10vw;
    margin-top: 10vh;
    transition: transform 0.1s ease-in-out;
}
.service__right:hover,
.service__left:hover{
    transform: scale(1.05);
}
.service__right button,
.service__left button{
    width: 12vw;
    height: 2.75vw;
    background-color: rgba(227, 131, 206, 0.2);
    border-radius: 0.5vw;
    color: white;
    border: 1px solid blue;
    font-family: Arial;
    font-weight: bold;
    font-size: 1.8vh;
    position: absolute;
    margin-top: -5%;
    margin-left: 4vw;
}
.price__left{
    position: absolute;
    margin-top: -4%;
    font-size: 1.8vh;
    margin-left: 18vw;
    color: gray;
    font-weight: bold;
}
.rocket
{
    margin-left: 68vw !important;
}
.res_des
{
    margin-left: 55.9vw !important;
}
.seo{
    margin-left: 72vw !important;
}
.price__right
{
    position: absolute;
    font-size: 1.8vh;
    margin-left: 18vw;
    color: gray;
    font-weight: bold;
    margin-top: -4%;
    margin-left: 56vw;
}
.service__right h2
{
    margin-left: 69vw;
}
.service__right p
{
    text-align: right;
    margin-right: 4vw;
}
.service__right button
{
    margin-left: 64vw;
}

.service__wrap > :last-child {
}
.text__big
{
    margin-left: 0 !important;
    margin-right: 4vw !important;
}
.service__right.big button{
    margin-left: 64vw !important;
    margin-top: -11.5% !important;

}
.service__right.big a
{
    margin-top: -10.75% !important;
}
.service__right.big:last-of-type{
    margin-bottom: 20vh;
}

@media (max-width:720px) {
    a{
        display: inline !important ;
    }
    .service__wrap
    {
        padding-top: 10vh;
    }
    .serv__cont{
        width: 90vw;
    }
    .serv__cont img
    {
        height: 10vh;
    }
    .img__big1
    {
       width: 30vw !important;
       height: auto !important;
    }
    .img__big2
    {
        width: 30vw !important;
       height: auto !important;
    }
    .serv__cont {
        flex-direction: column-reverse;
        justify-content: center !important;
        display: flex;
        flex-wrap: wrap;
        text-align: center !important;
    }
    .serv__cont p {
        order: 1;
        font-size: 1.8vh;
        width: 80%;
        margin-top: 0;
        margin-left: 5%;
    }
    .serv__cont img {
        order: 2;
        margin-top: -5%;
        width: 55% !important;
        height: auto;
        margin-left: 15%;
        margin-bottom:8vh;

    }
    .service__right.big img
    {
        width: 60vw !important;
        height: auto !important;
        margin-left: 6vw;
        margin-bottom: 10% !important;
        margin-top: -5vh !important;
    }
    .service__right p
    {
        text-align: center;
    }
    .service__right,
    .service__left
    {
        height: 55vh;
        position: relative;
    }
    .service__cont h2
    {
        margin-top: 47.5% !important;
        position: absolute;
        font-size: 3vh;
    }
    .serv__cont.idea img{
        margin-bottom: 0% !important;
        margin-left: 17%;
    }
    .service__right button,
.service__left button{

    width: 40vw;
    height: 5vh;
    position: absolute;
    bottom: 5%;
    margin-left: 5% !important;
    border-radius: 5vw;
    }
    .price__left
    {
        position: absolute;
        bottom: 7.5%;
        font-size: 1.8vh;
        margin-left: 60%;
        color: gray;
        font-weight: bold;
    }
    .service__right.big button{
        margin-left: 25% !important;
    }
    .price__right{
        position: absolute;
        bottom: 7.5%;
        font-size: 1.8vh;
        margin-left: 60%;
        color: gray;
        font-weight: bold;
    }
    .rocket
    {
        margin-left: 30vw !important;
    }
    .idea_z
    {
        margin-left: 32vw !important;
    }
    .service__right h2,
    .service__left h2
    {
        margin-left: 25vw;
        margin-top: 23.5vh !important;
    }
    .service__cont :nth-child(4) img{
        height: 20vh !important;
        width: 32vw !important;
        margin-left: 22vw;
    }
    .service__cont :nth-child(4) h2
    {
        margin-top: 20.5vh !important;
        margin-left: 15vw !important;
    }
    .service__cont :nth-child(5) h2
    {
        margin-top: 19.5vh !important;
        margin-left: 15vw !important;
    }
    .service__cont :nth-child(5) img{
        margin-bottom: 10vh;
    }
    .service__cont :nth-child(6) h2
    {
        margin-top: 22.5vh !important;
        margin-left:35vw !important;
    }
    .service__wrap h1{
        font-size: 6vh;
    }
    .romka{
        font-size: 3vh !important;
    }
    .service__cont div button{
        margin-left: 25% !important;
    }
}