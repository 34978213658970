/* src/components/Chatbot.css */

/* Chatbot container positioning */
.chatbot {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    font-family: 'Roboto', 'Arial', sans-serif;
}

/* Floating toggle button */
.chatbot-toggle {
    background-color: #1a1a2e; /* Dark blue color to match website */
    color: white;
    padding: 12px 20px;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}
.chatbot-close {
    background-color: #1a1a2e; /* Dark blue color to match website */
    color: white;
    padding: 12px 20px;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}
.chatbot-toggle:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

/* Chatbot window with larger size */
.chatbot-window {
    width: 400px;
    max-width: 90vw;
    height: 600px;
    max-height: 80vh;
    background-color: #0d1b2a; /* Dark background to blend with website */
    border-radius: 16px;
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    animation: fadeInUp 0.3s ease;
    border: 1px solid #162447;
}

/* Smooth fade-in animation */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Chatbot header without close icon */
.chatbot-header {
    padding: 10px 15px;
    background-color: #1a1a2e;
    color: #e5e5e5;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

/* Chatbot messages container */
.chatbot-messages {
    flex-grow: 1;
    padding: 15px;
    overflow-y: auto;
    background-color: #0d1b2a;
}

/* Chatbot messages styling with line wrap */
.message {
    font-family: 'Roboto', 'Arial', sans-serif;
    padding: 12px 15px;
    margin: 8px 0;
    border-radius: 16px;
    max-width: 75%;
    word-wrap: normal; /* Ensures long messages wrap to the next line */
    line-height: 1.4;
    font-size: 14px;
    font-weight: 500;
}

/* AI-helper messages on the left with a blue shade */
.bot {
    background-color: #0078d4;
    color: white;
    align-self: flex-start;
    border-top-left-radius: 0;
    border-bottom-right-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 120, 212, 0.3);
    text-align: left;
}

/* User messages on the right with a darker shade */
.user {
    background-color: #1a1a2e;
    color: white;
    align-self: flex-end;
    border-top-right-radius: 0;
    border-bottom-left-radius: 16px;
    box-shadow: 0 4px 8px rgba(22, 36, 71, 0.3);
    text-align: right;
}

/* Chatbot input container */
.chatbot-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #162447;
    background-color: #1a1a2e;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

/* Input styling */
.chatbot-input input {
    flex-grow: 1;
    padding: 8px 10px;
    border: 1px solid #162447;
    border-radius: 50px;
    font-size: 14px;
    margin-right: 8px;
    background-color: #0d1b2a;
    color: white;
    transition: border-color 0.2s;
}

.chatbot-input input::placeholder {
    color: #e5e5e5;
}

.chatbot-input input:focus {
    border-color: #0078d4;
    outline: none;
}

/* Send button styling */
.chatbot-input button {
    background-color: #0078d4;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.chatbot-input button:hover {
    background-color: #005bb5;
}

/* Responsive design */
@media (max-width: 480px) {
    .chatbot {
        bottom: 10px;
        right: 10px;
    }

    .chatbot-toggle {
        margin-right: 5vw;
        font-size: 14px;
    }

    .chatbot-window {
        width: 90vw;
        height: 70vh;
        max-height: 80vh;
    }
}
