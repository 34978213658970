.wrapper
{
    font-family: 'Arial-Bold', Arial, sans-serif;
    font-weight: 400;
}
.container
{
    max-width: 1920px;
    margin: 0px;
    padding: 0px ;
    margin-top:20px;
    /* margin-left: 5vw;
    margin-right: 25vw; */
}
.header
{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 5;
}
.header::before
{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 12vh;
    z-index: 2;
}

.header__body{
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 12vh;
    align-items: center;
}
.header__logo
{
    margin-top: 0%;
    flex: 0 0 7%;
    position: relative;
    z-index: 3;
    margin-left: 10%;
    position: absolute;
}

.header__logo img{
    width: auto;
    height: 3.5vw;
    margin-top: 15%;
    position: relative;
}
/* .header__list li:last-child button::after {
    text-align: center;
    content: 'WHITEWEB WORKS';
    color: rgb(168, 95, 95);
    position: absolute;
    font-size: 2.5vh;
    width: 30vw;
    height: auto;
    margin-left: -80vw;
    color: rgb(32, 26, 26);
    font-family: Arial;
    font-weight: bold;
} */
.upper__links
{
    margin-top: 20vh;
    margin-left:8vw;
    z-index: 2;
    width: 50vw;
    position: relative;
}
.web__head
{
    position: absolute;
    color: rgb(255, 255, 255);
    z-index: 3;
    margin-left: 15vw;
}
.web__head h1
{
    color: rgb(255, 255, 255);
    font-size: 2.5vh;
    margin-top: 2.5vh;
}
.upper__links a
{
    color: rgb(255, 255, 255);
    font-size: 2.5vh;
    padding: 2vw;
    text-decoration: none;
    font-weight: bold;

}
.upper__links a:hover
{
    color: rgb(93, 93, 214);
    transition: all 0.5s ;
}
.header__list{
    display: flex;
    position: relative;
    z-index: 2;
    margin-right: 10vw;
    margin-left: auto;
}
.header__list li{
    list-style: none;
    margin: 0px 20px 0px 30px;
    display: block;
}
.header__link{
    color: #101c54;
    font-weight:600;
    font-size: 1.8vh;
    text-decoration: none;
    transition: 0.5s ease;
}

/* .header__link.active {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
}

.header__link.active::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150%;
    height: 150%;
    background-color: #101c54;
    border-radius: 50px;
    z-index: -1;
    transition: width 0.3s, height 0.3s;
} */
li button
{
    width: 11vw;
    height: 2.75vw;
    background-color: blue;
    border-radius: 0.5vw;
    color: white;
    border: 1px solid blue;
    font-family: Arial;
    font-weight: bold;
    font-size: 1.8vh;
    box-sizing: border-box;
}
.header__list li:nth-child(1) button,
.header__list li:nth-child(2) button,
.header__list li:nth-child(3) button {
  display: none;
}
.header__list li:last-child button
{
    background-color: rgba(128, 128, 128,0.2);
}
button a
{
    color: white;
    font-size: 1.8vh;
    text-decoration: none;
    display: block;  /* Сделает ссылку блочным элементом, чтобы она занимала всю площадь кнопки */
    height: 100%;  /* Заставит ссылку растягиваться по высоте кнопки *//* Чтобы текст был по центру */
    line-height: 2.5vw; 
}
    

.header__burger
{
    display: none;
}
@media (max-width:767px){
    .header__link_team.active
    {
        display: none !important;
    }
    .none
    {
        display: none !important;
    }
    .header__body
    {
        height: 50px;
    }
    .web__head h1
    {
        margin-top: 12.5vw;
        margin-left: 7.5vw;
    }
    .header__logo
    {
        flex: 0 0 40px;
    }
    .upper__links
    {
        display: none;
    }
    .header__logo img
    {
        height: 4vh;
        margin-left: -5vw;
        margin-top: 10vw;
    }
    .header__burger{
        display: block;
        position: relative;
        width: 30px;
        height: 20px;
        position: relative;
        z-index: 3;
        margin-left: 85%;
        margin-top: 7.5vw;
    }
    .header__burger::after,.header__burger::before{
        content: '';
        background-color: rgb(255, 255, 255);
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        transition: all 0.3s ease 0s;
    }
    .header__burger::before{
        top: 0;
    }
    .header__burger::after{
        bottom: 0;
    }
    .header__burger span {
        position: absolute;
        background-color: rgb(255, 255, 255);
        left: 0;
        width: 100%;
        height: 2px;
        top:9px;
        transition: all 0.3 ease 0s;
    }
    .header__burger.active::before{
        transform:rotate(45deg);
        top: 9px;

    }
    .header__burger.active::after{
        transform:rotate(-45deg);
        bottom: 9px;
    }
    .header__burger.active span
    {
        transform: scale(0);
    }
    .header__menu{
        position: fixed;
        top: -100%;
        left: 0;
        width: 100%;
        height: 90%;
        transition: all 0.3s ease 0s;
        background-color: rgb(255, 255, 255);
        padding: 70px 10px 20px 10px;
    }
    .header__menu.active{
        top: 0;
        background-color: #0E0824;
    }
    .header__list{
        display: block;
        margin-top: 10%;
        width: 100%;
        text-align: right;
    }
    .header__list li{
        margin: 0px 0px 20px 0px;
    }
    .header::before{
        height: 50px;
    }
    .header__link.active::after {
       display: none;
    }
    .header__link.active a{
        color: blue;
        width: auto;
        font-weight: bold;
    }
    .header__list li:nth-child(1) button,
.header__list li:nth-child(2) button,
.header__list li:nth-child(3) button {
  display: inline;
}
.header__list li button{
    width: auto;
    margin-right: 20vw;
    margin-bottom: 10vh;
    background-color:transparent !important;
    border: none;
}
.header__list li button a{
    font-size: 3.5vh;
    font-weight: 500;
}
}